module directives {
    export module logistics {
        interface ICostInvoiceScope extends ng.IScope {
            showSummary: boolean;
            summary: interfaces.logistics.ICostInvoiceSummary;
            apportionateAlc(): void;
            viewSummary(): void;
            gvwCostInvoices: uiGrid.IGridOptions;
            apiCostInvoices: uiGrid.IGridApi;
            costInvoices: Array<any>;
            removeClick(): void;
            remove({ Id }): void;
            ngDisabled: boolean;
            viewInvoiceRecon(): void;
            viewDocumentsClick(): void;
            viewDocuments({ Id }): void;
        }

        export class costInvoiceDirective implements ng.IDirective {
            public restrict: 'E';
            public templateUrl = 'templates/modules/applicationmain/logistics/costInvoiceDirectiveView.html';
            public scope = {
                showSummary: "=",
                summary: "=?",
                apportionateAlc: "&?",
                viewSummary: "&?",
                costInvoices: "=",
                generateCostInvoices: "&?",
                editClick: "&",
                remove: "&",
                ngDisabled: "=",
                viewDocuments: "&?"
            }

            constructor(private $timeout: ng.ITimeoutService, private bsLoadingOverlayService, private menuService: interfaces.applicationcore.IMenuService, private $state: ng.ui.IStateService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            link = ($scope: ICostInvoiceScope, $element: ng.IAugmentedJQuery) => {
                $scope.gvwCostInvoices = {
                    data: [],
                    enableFiltering: true,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    showGridFooter: true,
                    onRegisterApi: (gridApi) => {
                        $scope.apiCostInvoices = gridApi;
                        $scope.gvwCostInvoices.data = $scope.costInvoices;
                        this.$timeout(() => {
                            
                        });
                    },
                    columnDefs: [{
                        name: "EDIT",
                        field: "",
                        displayName: "",
                        enableFiltering: false,
                        width: 30,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.editClick({Id: row.entity.Id})">
                                <span class="fa fa-pencil"></span>
                            </button>
                        </div>`
                    }, {
                        name: "ENTITYCODE",
                        displayName: "Owner Entity",
                        field: "OwnerEntity",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }, {
                        name: "INVNUM",
                        displayName: "Invoice Number",
                        field: "InvoiceNumber",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }, {
                        name: 'INVDATE',
                        displayName: 'Invoice Date',
                        field: 'InvoiceDate',
                        enableCellEditOnFocus: false,
                        enableCellEdit: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                            enableSorting: false,
                            width: 150,
                            filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="invDate"></gts-date>
                                            
                                </div>`,
                        filter: {
                            condition: function (searchTerm, cellValue) {
                                if (cellValue) {
                                    return cellValue.isSameOrAfter(searchTerm);
                                } else {
                                    return false;
                                }
                            }
                        }
                    }, {
                        name: "REFERENCE",
                        displayName: "Reference",
                        field: "Reference",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }, {
                        name: "SUPPLIER",
                        displayName: "Supplier",
                        field: "Supplier",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }, {
                        name: "TOTVALUE",
                        displayName: "Total Invoice Value",
                        field: "TotalInvoiceValue",
                        type: "number",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "TOTVAT",
                        displayName: "Total VAT",
                        field: "TotalVAT",
                        type: "number",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "CURCODE",
                        displayName: "Currency",
                        field: "CurrencyCode",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }, {
                        name: "STATUS",
                        displayName: "Status",
                        field: "Status",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }, {
                        name: "INVRECONSTATUS",
                        displayName: "Invoice Recon Status",
                        field: "ReconStatus",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                    }]
                };

                $scope.removeClick = () => {
                    var selectedRows = $scope.apiCostInvoices.selection.getSelectedRows();

                    if(selectedRows && selectedRows.length > 0) {
                        $scope.remove({Id: selectedRows[0].Id});
                    }
                };

                $scope.viewDocumentsClick = () => {
                    var selectedRows = $scope.apiCostInvoices.selection.getSelectedRows();

                    if (selectedRows && selectedRows.length > 0) {
                        $scope.viewDocuments({ Id: selectedRows[0].Id });
                    } else {
                        this.generalService.displayMessage("Please select a Cost Invoice", Enum.EnumMessageType.Warning);
                    }
                };

                $scope.viewInvoiceRecon = () => {
                    var selectedRows = $scope.apiCostInvoices.selection.getSelectedRows();

                    if (selectedRows && selectedRows.length > 0) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'cost.invoice.direct'
                        }, () => {
                            return this.menuService.getGTSConnectMenuItem(525).get((result: interfaces.applicationcore.IMenu) => {
                                if (result.url) {
                                    result.url += "?Id=" + selectedRows[0].Id;
                                    var url = this.$state.href("auth.IFrame", { url: result.url });
                                    window.open(url, '_blank');
                                } else {
                                    this.generalService.displayMessage("Error - you attempted to access a page that you do not have rights to. Please contact your administrator if you think this is incorrect.", Enum.EnumMessageType.Error);
                                }
                            }).$promise;
                        });
                    } else {
                        this.generalService.displayMessage("Please select a Cost Invoice", Enum.EnumMessageType.Warning);
                    }
                    
                }
            }
            
            static factory(): ng.IDirectiveFactory {
                const directive = ($timeout, bsLoadingOverlayService, menuService, $state, generalService) => new costInvoiceDirective($timeout, bsLoadingOverlayService, menuService, $state, generalService);
                directive.$inject = ['$timeout', "bsLoadingOverlayService", "menuService", "$state", "generalService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsCostInvoice", costInvoiceDirective.factory());
    }
}